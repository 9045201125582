import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ColorScale from '_class/ColorScale';
import User from '_class/User';
import DateTime from '_class/DateTime';
import { useTranslate } from 'lib/translate';
import { capitalize } from 'helpers/content';

import { getUserSummary, getUserStatisticsDetailed } from 'actions/assessments';

import DisplayMatrix from 'components/Presentation/DisplayMatrix';
import DisplayGoal from 'components/Presentation/DisplayGoal';
import DisplayFeedbacks from 'components/Presentation/DisplayFeedbacks';

import { Spinner } from 'UI';
import { Bar } from 'UI/Graphs';
import { Block, Expandable, Icon, Flex } from '@haldor/ui';

import './_userAssessments.scss';

// TODO: (Next version?) calculate most values with useMemo to improve performance
const UserAssessments = (props) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState([]);
	const [loading, setLoading] = useState(true);
	const currentUser = useSelector(state => state.user.currentUser);
	const summary = useSelector(state => state.assessments?.studentOverview);
	const statistics = useSelector(state => state.assessments?.studentStatistics);

	const translate = useTranslate();
	const user = new User(currentUser);

	useEffect(async () => {
		let params = {};

		if (props.courseId != null && props.courseId != 0) {
			params.courseId = props.courseId;
		}

		if (props.sectionId != null && props.sectionId != 0) {
			params.sectionId = props.sectionId;
		}

		if (props.showOnlyMyAssessments != null && props.showOnlyMyAssessments) {
			params.type = "MY";
		}

		setLoading(true);

		const start = new DateTime(props.start).endOf('day').getUTCTimeStamp();
		const end = new DateTime(props.end).endOf('day').getUTCTimeStamp();

		await Promise.all([
			dispatch(getUserSummary(props.studentId, start, end, params)),
			dispatch(getUserStatisticsDetailed(props.studentId, start, end, params)),
		]);

		setLoading(false);

		return () => null;
	}, [props.studentId, props.start, props.end, props.courseId, props.sectionId, props.showOnlyMyAssessments])

	useEffect(() => {
		if (summary != null && open.length == 0) {
			setOpen(summary.courses.flatMap((course) => [
				`${course.id}-assessment`,
				`${course.id}-feedback`,
				`${course.id}-courseSubject`,
			]));
		}
	}, [summary]);

	const getSummaryCourses = () => {
		if (summary == null) {
			return [];
		}

		if (props.courseId != null) {
			return summary.courses.filter((course) => course.id == props.courseId);
		}

		return summary.courses;
	}

	/**
	 * @param {number} matrixId
	 * @returns {array} parts
	 */
	const getPartsByMatrixId = (matrixId) => {
		if (summary == null || !props.showMatrices) {
			return [];
		}

		return summary.assessmentBlockParts?.filter((part) =>
			part.referenceType == 'matrix' && part.referenceId == matrixId
		);
	}

	/**
	 * @param {number} courseId
	 * @returns {array} parts
	 */
	const getGoalPartsByCourseId = (courseId) => {
		if (summary == null || !props.showGoals) {
			return [];
		}

		let blockParts = summary.assessmentBlockParts;
		blockParts = blockParts.filter((part) => {
			if (part.referenceType == 'matrix') {
				return false;
			}

			return part.assessmentBlockPartRelationships.find((relationship) =>
				relationship.courseId == courseId
			) != null;
		});

		return blockParts;
	}

	/**
	 * @param {array} parts
	 * @returns {array} feedbacks
	 */
	const getFeedbacksForParts = (parts) => {
		if (summary == null) {
			return [];
		}

		return JSON.parse(JSON.stringify(summary.assessmentFeedbacks)).filter((feedback) =>
			parts.find((part) => feedback.assessmentBlockPartId == part.id) != null
		);
	}

	/**
	 * @returns {object} statistic
	 */
	const getStudentStatistic = () => {
		if (statistics == null || props.studentId == null) {
			return null;
		}

		return statistics.find((statistic) => statistic.studentId == props.studentId);
	}

	/**
	 * @param {object} part
	 * @returns {object} statistic
	 */
	const getPartStatistic = (part) => {
		return getStudentStatistic()?.studentStatistics?.find((statistic) =>
			statistic.type == part.referenceType.toUpperCase() && statistic.referenceId == part.referenceId
		);
	}

	/**
	 * @param {number} matrixId 
	 * @returns {object} statistic
	 */
	const getMatrixStatistic = (matrixId) => {
		return getStudentStatistic()?.studentStatistics?.find((statistic) =>
			statistic.type == 'MATRIX' && statistic.referenceId == matrixId
		);
	}

	const getPartTitle = (part, statistic, reference, index = 0) => {
		let colors = [];
		let extraTitle = null;
		let displayGraph = true;

		if (statistic != null && statistic.statistics != null) {
			let addRed = false;
			if (statistic.type == "MATRIX") {
				addRed = !reference.disableNotAchieved;

				displayGraph = statistic.statistics.find((stat) => stat.count > 0) != null;
			}

			if (statistic.type == "ASSESSMENTGOAL") {
				addRed = reference.rows.find((row) => {
					return row.cells.find((cell) => cell.type == 'NotAchievedTextField');
				}) != null;

				statistic.statistics.forEach((stat) => {
					if (stat.assessmentDetails != null && stat.assessmentDetails.length > 0) {
						extraTitle = stat.assessmentDetails[0].navigationObject.title;
					}
				});
			}

			colors = new ColorScale(statistic.statistics.length).get(addRed);
		}

		return <div className="df aic bar-outer-container">
			<div className="bar-title-container" style={{ marginRight: '.55rem' }}>
				{translate(part.referenceType)}

				{part.referenceType == 'assessmentGoal' ?
					' ' + index
					: null}

				{extraTitle != null ?
					' - ' + extraTitle
					: null}
			</div>

			{statistic != null && displayGraph ?
				part.referenceType == 'assessmentGoal' ?
					<div className='assessment-goals-dots'>
						{statistic.statistics.map((items, index) =>
							<div
								className="goal-dot"
								style={{ backgroundColor: `${items.count > 0 ? colors[items.index] : null}` }}
								key={index}
							/>
						)}
					</div>
					:
					<Bar
						data={statistic.statistics.map((stat) => ({
							amount: stat.count,
							label: stat.count,
							color: colors[stat.index],
						}))}
						showAmount
						showAllSteps
					/>
				: null}
		</div>
	}

	/**
	 * @param {string} target
	 * @param {array} permissions
	 * @param {string} referenceType
	 */
	const renderPermission = (target, permissions, referenceType) => {
		const active = permissions.find((perm) =>
			perm.target == target && perm.referenceType == referenceType
		) != null;

		return <div className="permission">
			<Icon name={active ? "Eye" : "Eye_Off"} />
			{translate(capitalize(target))}
		</div>
	}

	/**
	 * TODO: Replace this with the DisplayFeedbacks component since it pretty much contains the same code
	 * @param {object} feedback
	 * @param {number} index
	 */
	const renderFeedback = (feedback, index) => {
		const resource = summary?.assessmentBlockParts?.find((part) =>
			part.id == feedback.assessmentBlockPartId
		);

		let permissions = [];
		if (resource != null && !feedback.isPrivate) {
			permissions = resource.permissions;
		}

		return (
			<div className="feedback" style={{ paddingLeft: 0 }} key={feedback.id}>
				<div className="df size-12 feedback-item-header" style={{ marginBottom: '.4rem' }}>
					<div>
						{feedback.navigationObject != null ?
							feedback.navigationObject.referenceType == 'PLAN' ?
								translate('Plan')
								: translate('Assignment')
							: null}

						{feedback.navigationObject != null ?
							' • ' + feedback.navigationObject.title
							: null}
					</div>

					<div>
						{feedback.editorUser != null ?
							feedback.editorUser.firstName + ' ' + feedback.editorUser.lastName
							: null}

						{' • ' + new DateTime(feedback.published ? feedback.published : feedback.created).getLongDate()}
					</div>
				</div>

				<div className="df size-12 feedback-item">
					<div
						dangerouslySetInnerHTML={{ __html: feedback.text }}
					/>

					{!user.isStudent() ?
						<div className="permissions">
							{renderPermission('STUDENT', permissions, 'ASSESSMENTFEEDBACK')}
							{renderPermission('GUARDIAN', permissions, 'ASSESSMENTFEEDBACK')}
						</div>
						: null}
				</div>
			</div>
		);
	}

	const renderAssessmentsWithOnlyFeedback = () => {
		if (summary == null || summary.assessmentBlockParts == null) {
			return null;
		}

		const parts = summary.assessmentBlockParts.filter((blockPart) =>
			blockPart.assessmentBlockPartRelationships?.length == 0
		);

		if (parts == null || parts.length == 0) {
			return null;
		}

		const isOpen = open.indexOf(`only-feedbacks`) > -1;

		return (
			<Block>
				<Flex center space>
					<span className="title" style={{ '--color': '#777' }}>
						{translate('Assessments with only feedback')}
					</span>

					<div
						className={isOpen ? "expand-all active" : "expand-all"}
						onClick={() => toggleSection('only', 'feedbacks')}
					>
						<Icon name="ArrowLeft" />
						{isOpen ?
							translate('Close all')
							: translate('Show all')}
					</div>
				</Flex>

				<Expandable
					color="#777"
					open={open.includes(`only-feedbacks`)}
					onChange={() => toggleSection('only', 'feedbacks')}
					title={translate('Collected feedback')}
				>
					<div className="course-content collected-feedback">
						{parts.map((part, index) => {
							if (part == null || part.referenceType != 'assessmentonlyfeedback') {
								return null;
							}

							const feedbacks = getFeedbacksForParts([part]).filter((f) => !f.isPrivate);
							if (feedbacks == null || feedbacks.length == 0) return null;

							return (
								<Expandable ignorePropsUpdate open key={part.id} title={getPartTitle(part, null, null, index + 1)}>
									{feedbacks != null && feedbacks.length > 0 ?
										<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
											{feedbacks.map(renderFeedback)}
										</div>
										: null}
								</Expandable>
							);
						})}
					</div>
				</Expandable>

				{user.isMentor() || user.isTeacher() ?
					<Expandable title={translate('Collected personal notes')}>
						<div className="course-content">
							{parts.map((part, index) => {
								if (part == null || part.referenceType != 'assessmentonlyfeedback' || currentUser == null) {
									return null;
								}

								const feedbacks = getFeedbacksForParts([part]).filter((f) => (f.isPrivate && f.editorUser != null && f.editorUser.userId == currentUser.id));
								if (feedbacks == null || feedbacks.length == 0) return null;

								return (
									<Expandable key={part.id} title={translate('Personal note')}>
										<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
											{feedbacks.map(renderFeedback)}
										</div>
									</Expandable>
								);
							})}
						</div>
					</Expandable> : null}
			</Block>
		);
	}

	const renderGoalsWithoutCourse = () => {
		if (summary == null || summary.assessmentBlockParts == null) {
			return null;
		}

		const parts = summary.assessmentBlockParts.filter((goal) =>
			goal.assessmentBlockPartRelationships == null || goal.assessmentBlockPartRelationships.length == 0
		);

		if (parts == null || parts.length == 0) {
			return null;
		}

		const isOpen = open.indexOf(`goals-assessment`) > -1 || open.indexOf(`goals-feedback`) > -1;

		return (
			<Block>
				<Flex center space>
					<span className="title" style={{ '--color': '#777' }}>
						{translate('Assessment goals without course relation')}
					</span>

					<div
						className={isOpen ? "expand-all active" : "expand-all"}
						onClick={() => toggleCourse('goals')}
					>
						<Icon name="ArrowLeft" />
						{isOpen ?
							translate('Close all')
							: translate('Show all')}
					</div>
				</Flex>

				<Expandable
					color="#777"
					open={open.includes(`goals-assessment`)}
					onChange={() => toggleSection('goals', 'assessment')}
					title={translate('Assessment')}
				>
					<div className="course-content">
						{parts.map((part, index) => {
							if (part == null) {
								return null;
							}

							const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);
							const statistic = getPartStatistic(part);
							const feedbacks = getFeedbacksForParts([part]);

							if ((goal == null || goal.rows == null || goal.rows.length == 0) && (feedbacks == null || feedbacks?.length == 0)) {
								return null;
							}

							return (
								<Expandable
									ignorePropsUpdate
									open
									key={index}
									title={getPartTitle(part, statistic, goal, index + 1)}
								>
									{goal != null ?
										<DisplayGoal goal={goal} statistic={statistic} />
										: null}

									{feedbacks != null && feedbacks.length > 0 ?
										<DisplayFeedbacks feedbacks={feedbacks} resource={part} />
										: null}
								</Expandable>
							);
						})}
					</div>
				</Expandable>

				<Expandable
					color="#777"
					open={open.includes(`goals-feedback`)}
					onChange={() => toggleSection('goals', 'feedback')}
					title={translate('Collected feedback')}
				>
					<div className="course-content collected-feedback">
						{parts.map((part, index) => {
							const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);

							if (goal == null || part == null) {
								return null;
							}

							const feedbacks = getFeedbacksForParts([part]).filter((f) => !f.isPrivate);
							const statistic = getPartStatistic(part);
							if (feedbacks == null || feedbacks.length == 0) return null;

							return (
								<Expandable ignorePropsUpdate open key={goal.id} title={getPartTitle(part, statistic, goal, index + 1)}>
									{feedbacks != null && feedbacks.length > 0 ?
										<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
											{feedbacks.map(renderFeedback)}
										</div>
										: null}
								</Expandable>
							);
						})}
					</div>
				</Expandable>

				{user.isMentor() || user.isTeacher() ?
					<Expandable title={translate('Collected personal notes')}>
						<div className="course-content">
							{parts.map((part, index) => {
								const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);

								if (goal == null || part == null) {
									return null;
								}

								const feedbacks = getFeedbacksForParts([part]).filter((f) => f.isPrivate);
								const statistic = getPartStatistic(part);
								if (feedbacks == null || feedbacks.length == 0) return null;

								return (
									<Expandable key={goal.id} title={translate('Personal note')}>
										<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
											{feedbacks.map(renderFeedback)}
										</div>
									</Expandable>
								);
							})}
						</div>
					</Expandable>
					: null}
			</Block>
		);
	}

	/**
	 * @param {number} id
	 * @param {string} section
	 */
	const toggleSection = (id, section) => {
		let newOpen = [...open];
		const identifier = `${id}-${section}`;
		const index = open.indexOf(identifier);

		if (index > -1) {
			if (section === 'courseSubject') {
				newOpen.filter(x => x !== `${id}-assessment` && x !== `${id}-feedback`);
			}

			newOpen.splice(index, 1);
		} else {
			newOpen.push(identifier);

			if (section === 'courseSubject') {
				newOpen.push(`${id}-assessment`);
				newOpen.push(`${id}-feedback`);
			}
		}

		setOpen(newOpen);
	}

	const toggleAll = (e) => {
		e.preventDefault();

		if (open.length > 0) {
			setOpen([]);
		} else {
			if (summary != null) {
				setOpen(summary.courses.flatMap((course) => [
					`${course.id}-assessment`,
					`${course.id}-feedback`,
					`${course.id}-courseSubject`,
					`goals-assessment`,
					`goals-feedback`,
					`only-feedbacks`
				]));
			}
		}
	}

	/**
	 * @param {number} courseId
	 */
	const toggleCourse = (courseId) => {
		let assessment = open.indexOf(`${courseId}-assessment`);
		let feedback = open.indexOf(`${courseId}-feedback`);
		if (assessment > -1 || feedback > -1) {
			let newOpen = [...open];
			if (assessment > -1)
				newOpen.splice(assessment, 1);

			feedback = newOpen.indexOf(`${courseId}-feedback`);
			if (feedback > -1)
				newOpen.splice(feedback, 1);

			setOpen(newOpen);
		} else {
			setOpen([
				`${courseId}-assessment`,
				`${courseId}-feedback`,
				...open,
			]);
		}
	}

	if (loading) {
		return (
			<div className="user-asessments assessment-block">
				<div style={{ marginBottom: '1rem' }}>
					<h3>{translate('knowledge-overview')}</h3>
				</div>

				<Spinner center />
			</div>
		);
	}



	return (
		<div className="user-assessments assessment-block">
			<div style={{ marginBottom: '1rem' }}>
				<Flex center space>
					<h3>{translate('knowledge-overview')}</h3>

					{summary != null ?
						<div
							className={open.length > 0 ? "expand-all active" : "expand-all"}
							onClick={toggleAll}
						>
							<Icon name="ArrowLeft" />

							{open.length > 0 ?
								translate('Close all')
								: translate('Show all')}
						</div>
						: null}
				</Flex>
			</div>

			{summary != null ?
				getSummaryCourses().map((course) => {
					// TODO: Condense and optimize this render method
					// Do we have unnecessary data or do we fetch the same data twice?
					const schoolType = translate(course.typeOfSchooling);
					const courseTitle = `${course.title} - ${course.year != null ? course.year : ''} ${course.courseCode} (${schoolType})`;
					const courseGoalParts = getGoalPartsByCourseId(course.id);
					let isOpen = open.indexOf(`${course.id}-courseSubject`) > -1;;

					const titleHeader = (
						<div style={{ display: 'flex', flexDirection: 'row' }}>

							<span style={{ display: 'flex', flexGrow: 1 }}>
								{courseTitle}
							</span>

							<div
								className={isOpen ? "expand-all active" : "expand-all"}
								onClick={() => toggleCourse(course.id)}
								style={{ marginRight: '1.6rem' }}
							>
								<Icon name="ArrowLeft" />
								<div style={{ fontSize: '1rem', fontWeight: 'normal' }}>
									{isOpen ?
										translate('Close all')
										: translate('Show all')}
								</div>
							</div>
						</div>
					);

					return <div className={'assessmentBlockContainer'}>
						<Block key={course.id}>

							<Expandable
								open={open.includes(`${course.id}-courseSubject`)}
								onChange={() => toggleSection(course.id, 'courseSubject')}
								color={course.colorCode}
								title={titleHeader}
							>
								<Expandable
									open={open.includes(`${course.id}-assessment`)}
									onChange={() => toggleSection(course.id, 'assessment')}
									color={course.colorCode}
									title={translate('Assessment')}
								>
									<div className="course-content">
										{courseGoalParts.map((part, index) => {
											if (part == null) {
												return null;
											}

											const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);
											const statistic = getPartStatistic(part);
											const feedbacks = getFeedbacksForParts([part]);

											if ((goal == null || goal.rows == null || goal.rows.length == 0) && (feedbacks == null || feedbacks.length == 0)) {
												return null;
											}

											return (
												<Expandable ignorePropsUpdate open key={index} title={getPartTitle(part, statistic, goal, index + 1)}>
													{goal != null ?
														<DisplayGoal goal={goal} statistic={statistic} />
														: null}

													{feedbacks != null && feedbacks.length > 0 ?
														<DisplayFeedbacks feedbacks={feedbacks} resource={part} isStudentOverView={props.studentOverView} />
														: null}
												</Expandable>
											);
										})}

										{course.details.map((detail) => {
											const parts = getPartsByMatrixId(detail.matrixID);

											if (parts == null || parts.length == 0) {
												return null;
											}

											const statistic = getMatrixStatistic(detail.matrixID);
											const feedbacks = getFeedbacksForParts(parts);

											return (
												<Expandable ignorePropsUpdate key={detail.matrixID} title={getPartTitle(parts[0], statistic, detail.matrix)}>
													<DisplayMatrix matrix={detail.matrix} statistic={statistic} />

													{feedbacks != null && feedbacks.length > 0 ?
														<DisplayFeedbacks feedbacks={feedbacks} resources={parts} />
														: null}
												</Expandable>
											);
										})}
									</div>
								</Expandable>

								<Expandable
									open={open.includes(`${course.id}-feedback`)}
									onChange={() => toggleSection(course.id, 'feedback')}
									color={course.colorCode}
									title={translate('Collected feedback')}
								>
									<div className="course-content collected-feedback">
										{courseGoalParts.map((part, index) => {
											const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);

											if (goal == null || part == null) {
												return null;
											}

											const feedbacks = getFeedbacksForParts([part]).filter((f) => !f.isPrivate);
											const statistic = getPartStatistic(part);
											if (feedbacks == null || feedbacks.length == 0) return null;

											return (
												<Expandable ignorePropsUpdate open key={goal.id} title={getPartTitle(part, statistic, goal, index + 1)}>
													<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
														{feedbacks.map(renderFeedback)}
													</div>
												</Expandable>
											);
										})}

										{course.details.map((detail) => {
											const parts = getPartsByMatrixId(detail.matrixID);

											if (parts == null || parts.length == 0) {
												return null;
											}

											const feedbacks = getFeedbacksForParts(parts).filter((f) => !f.isPrivate);
											if (feedbacks == null || feedbacks.length == 0) return null;

											return (
												<Expandable ignorePropsUpdate open key={detail.matrixID} title={getPartTitle(parts[0], null, detail.matrix)}>
													<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
														{feedbacks.map(renderFeedback)}
													</div>
												</Expandable>
											);
										})}
									</div>
								</Expandable>

								{user.isMentor() || user.isTeacher() ?
									<Expandable color={course.colorCode} title={translate('Collected personal notes')}>
										<div className="course-content collected-feedback">
											{courseGoalParts.map((part, index) => {
												const goal = summary.assessmentGoals.find((goal) => goal.id == part.referenceId);

												if (goal == null || part == null) {
													return null;
												}

												const feedbacks = getFeedbacksForParts([part]).filter((f) => f.isPrivate);
												const statistic = getPartStatistic(part);
												if (feedbacks == null || feedbacks.length == 0) return null;

												return (
													<Expandable key={goal.id} title={translate('Personal note')}>
														<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
															{feedbacks.map(renderFeedback)}
														</div>
													</Expandable>
												);
											})}

											{course.details.map((detail) => {
												const parts = getPartsByMatrixId(detail.matrixID);

												if (parts == null || parts.length == 0) {
													return null;
												}

												const feedbacks = getFeedbacksForParts(parts).filter((f) => f.isPrivate);
												if (feedbacks == null || feedbacks.length == 0) return null;

												return (
													<Expandable key={detail.matrixID} title={getPartTitle(parts[0], null, detail.matrix)}>
														<div className="display-feedbacks" style={{ padding: 0, border: 0, margin: 0 }}>
															{feedbacks.map(renderFeedback)}
														</div>
													</Expandable>
												);
											})}
										</div>
									</Expandable>
									: null}
							</Expandable>

						</Block>
					</div>
				})
				: null}

			{props.courseId == null ?
				renderGoalsWithoutCourse()
				: null}

			{props.courseId == null ? renderAssessmentsWithOnlyFeedback() : null}
		</div>
	);
}

UserAssessments.defaultProps = {
	showGoals: true,
	showMatrices: true,
};

export default UserAssessments;